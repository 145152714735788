import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "../pages/Home";
import { Users } from "../pages/Users";
import { Sidebar } from "../components/Sidebar";
import { Header } from "../components/Header";
import { Company } from "../pages/Company";
import { AddCompany } from "../pages/Company/AddCompany";
import { Services } from "../pages/Service";
import { AddService } from "../pages/Service/AddService";
import { EditService } from "../pages/Service/EditService";

export const RoutesApp = () => {
  return (
    <div>
      <Sidebar />
      <div className="xl:pl-72">
        <Header />

          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/users" element={<Users />} />

            <Route path="/companies" element={<Company />} />
            <Route path="/companies/add-company" element={<AddCompany />} />

            <Route path="/services" element={<Services />} />
            <Route path="/services/add-service" element={<AddService />}/>
            <Route path="/services/edit-service/:id/:name/:average" element={<EditService />}/>
          </Routes>
      </div>
    </div>
  );
};
