import { RoutesApp } from "./routes";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

export default function App() {
  return (
    <BrowserRouter basename="">
      <RoutesApp />
    </BrowserRouter>
  );
}
