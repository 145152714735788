import { useForm, Controller } from "react-hook-form";
import { ICompany } from "../../../interfaces/company.interface";
import { postCompany } from "../../../services/company.service";
import { Container } from "../../../components/Container";
import { Link } from "react-router-dom";

export const AddCompany = () => {
  const { control, handleSubmit, reset } = useForm<ICompany>();

  const handleCancel = () => {
    reset();
  };

  const onSubmit = (data: ICompany) => {
    postCompany(data)
      .then(() => {
        window.alert("Sucesso ao criar empresa!");
      })
      .catch(() => {
        window.alert("Erro ao criar empresa!");
      })
      .finally(() => {
        reset();
      });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Cadastro de Empresa
            </h2>

            {/* <p className="mt-1 text-sm leading-6 text-gray-600"></p> */}

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nome Empresa
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          name="name"
                          id="name"
                          className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="XXXXXXXX"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="cnpj"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  CNPJ
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <Controller
                      name="cnpj"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          type="text"
                          name="cnpj"
                          id="cnpj"
                          className="block flex-1 border-0 bg-transparent pl-3 py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="XX.XXX.XXX/XXXX-XX"
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link to="/companies">
            <button
              type="button"
              onClick={() => handleCancel()}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancelar
            </button>
          </Link>

          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Salvar
          </button>
        </div>
      </form>
    </Container>
  );
};
