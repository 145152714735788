import { AxiosError, AxiosResponse } from "axios";
import api from "./api";
import { ICompany } from "../interfaces/company.interface";

const baseURL = "company";


export const postCompany = (data: ICompany) => {
  const resonse = api.post(`${baseURL}`, data);
  return resonse;
};

export const getAllCompanies = (): Promise<ICompany[]> =>
  api
    .get("/company")
    .then((response: AxiosResponse<ICompany[]>) => response.data);

export const getCompanyId = (id: number): Promise<ICompany[]> =>
  api
    .get(`/company/${id}`)
    .then((response: AxiosResponse<ICompany[]>) => response.data);
