import { useQuery } from "@tanstack/react-query";
import { Container } from "../../../components/Container";
import { COMPANY } from "../../../utils/pt";
import { Link } from "react-router-dom";
import { getServices } from "../../../services/services.service";
import { IService } from "../../../interfaces/services.interface";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { DeleteService } from "../DeleteService";
import { useState } from "react";

export const ListServices = () => {
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [infoRow, setInfoRow] = useState<IService>();
  
  const { data } = useQuery({
    queryKey: ["services"],
    queryFn: getServices,
  });

  const handleOpenModalDelete = (item: IService) => {
    setInfoRow(item);
    setOpenModalDelete(!openModalDelete);
  }

  return (
    <Container>
      <>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Lista de Serviços
            </h1>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Link to="/services/add-service">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Adicionar Serviço
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      SERVIÇO
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      TEMPO DE EXECUÇÂO
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {COMPANY.STATUS.toLocaleUpperCase()}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.map((item: IService) => (
                    <tr key={Math.random()}>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div>
                            <div className="font-medium text-gray-900">
                              {item.name}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {item.averageExecutionTime}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          Active
                        </span>
                      </td>

                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <Link to={`/services/edit-service/${item?.id}/${item?.name}/${item?.averageExecutionTime}`}>
                        <button
                          type="button"
                        >
                          <PencilSquareIcon className="h-5 w-5 text-indigo-500" aria-hidden="true" />
                        </button>
                        </Link>

                        <button
                          type="button"
                          className="ml-4"
                          onClick={() => handleOpenModalDelete(item)}
                        >
                          <TrashIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <DeleteService id={infoRow?.id} open={openModalDelete} setOpen={setOpenModalDelete} />
      </>
    </Container>
  );
};

